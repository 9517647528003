const baseUrl = process.env.REACT_APP_API_CADASTRO_CERTO_URL

export const getUserDetails = async (login, token) => {

    const url = `${baseUrl}/usuario/findUserByLogin?login=${login}`;

    return await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    });
}

export const columnDefs = [

    {headerCheckboxSelection: true, checkboxSelection: true, width: 50, floatingFilter: false},
    {headerName: 'ID', field: 'id', editable: false},
    {headerName: 'Descrição', field: 'descricao', minWidth: 300},
    {headerName: 'Status', field: 'status', editable: false},
    {
        headerName: 'NCM', field: 'ncm', editable: true,
        cellRenderer: (params) => {
            return <span>{
                params.value ? params.value.codigo : ''
            }</span>;
        }
    },
    {
        headerName: 'CEST', field: 'cest', editable: true,
        cellRenderer: (params) => {
            return <span>{
                params.value ? params.value.codigo : ''
            }</span>;
        }
    },
    {
        headerName: 'Data Hora Inclusão', field: 'dataHoraInclusao', editable: false,
        cellRenderer: (params) => {
            const originalDateTime = new Date(params.value);
            const brazilDateTime = originalDateTime.toLocaleDateString('pt-BR') + ' ' + originalDateTime.toLocaleTimeString('pt-BR');
            return <span>{
                brazilDateTime
            }</span>;
        }
    },

    {
        headerName: 'Data hora de Alteração', field: 'datahoraAlteracao', editable: false,
        cellRenderer: (params) => {
            const originalDateTime = new Date(params.value);
            const brazilDateTime = originalDateTime.toLocaleDateString('pt-BR') + ' ' + originalDateTime.toLocaleTimeString('pt-BR');
            return <span>{
                brazilDateTime
            }</span>;
        }
    },
    {
        headerName: 'Usuário Ult. Alteração', field: 'usuarioUltimaAlteracao', editable: false,
        cellRenderer: (params) => {
            return <span>{
                params.value ? params.value.apelido + " - " + params.value.login : ''
            }</span>;
        }
    },

    {headerName: 'Origem da Mercadoria', field: 'origemMercadoria', editable: false,},

    {
        headerName: 'Fabricante ', field: 'fabricante', editable: false,
        cellRenderer: (params) => {
            return <span>{
                params.value ? params.value.razaoSocial : ''
            }</span>;
        }
    },

    {headerName: 'Lista DCB', field: 'dcbLista', editable: false,},

    {
        headerName: 'DCB ', field: 'dcb', editable: false,
        cellRenderer: (params) => {
            return <span>{
                params.value ? params.value.nome : ''
            }</span>;
        }
    },

    {headerName: 'Categoria', field: 'categoriaMedicamento', editable: false},

    {headerName: 'Tipo preço', editable: false, field: 'tipoPreco'},

    // {headerName: 'Grupo Cliente', editable: false},  // grupo do cliente que incluiu o produdo

    // {headerName: 'Data Emissão Nf-e', editable: false}, // data da nfe do cliente  que incluiu o produdo


];

export const localeText = {
    // Number Filter & Text Filter
    filterOoo: 'Filtro...',
    equals: 'Igual',
    notEqual: 'Diferente',
    blank: 'Em Branco',
    notBlank: 'Não Em Branco',
    empty: 'Escolha Um',

    // Number Filter
    lessThan: 'Less than',
    greaterThan: 'Greater than',
    lessThanOrEqual: 'Less than or equal',
    greaterThanOrEqual: 'Greater than or equal',
    inRange: 'In range',
    inRangeStart: 'from',
    inRangeEnd: 'to',

    // Text Filter
    contains: 'Contém',
    notContains: 'Não Contém',
    startsWith: 'Inicia com',
    endsWith: 'Termina com',

    // Filter Conditions
    andCondition: 'E',
    orCondition: 'Ou',
};


export const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    resizable: true,
    floatingFilter: true
}

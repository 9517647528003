export const pages = [
    {
        page: "Home",
        route: "/"
    },
    {
        page: "Produtos",
        route: "/produto"
    },
    {
        page: "ICMS",
        route: "/"
    },
    {
        page: "PIS",
        route: "/"
    },
    {
        page: "COFINS",
        route: "/"
    },
    {
        page: "Fabricante",
        route: "/"
    }
]

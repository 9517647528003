import {Link as RouterLink, useLocation} from 'react-router-dom';
import logo from '../../assets/LOGO-NAV_BAR.png'
import styles from './NavBar.module.css'
import {AppBar, Avatar, Tab, Tabs, Toolbar, useTheme, useMediaQuery} from '@mui/material'
import {useState} from "react";
import DrawerComponent from "./DrawerComponent";
import {pages} from "./Pages";


function NavBar() {
    const [value, setValue] = useState();
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));

    const userInitials = localStorage.getItem("userInitials");
    const location = useLocation();
    const currentPath = location.pathname;

    if (currentPath === "/login") {
        return (<></>)
    } else {
        return (
            <>
                <AppBar sx={{backgroundColor: "#222"}}>
                    <Toolbar>
                        <> <img className={styles.img} src={logo} alt="cadastro Certo"/></>
                        {isMatch ? (
                            <>
                                <DrawerComponent/>
                            </>
                        ) : (
                            <>
                                <Tabs sx={{marginLeft: 'auto'}}
                                      value={value}
                                      indicatorColor="primary"
                                      onChange={(e, value) => setValue(value)}
                                >
                                    {

                                        pages.map((page, index) => (
                                            <Tab key={index} label={page.page} component={RouterLink} to={page.route}
                                                 style={{color: '#FFF', fontWeight: 'bold'}}/>
                                        ))
                                    }
                                </Tabs>

                                <Avatar sx={{marginLeft: "auto", bgcolor: "#FF5733"}}>{
                                    userInitials
                                }</Avatar>
                            </>
                        )}
                    </Toolbar>
                </AppBar>

                <br/><br/>
                <br/>
            </>
        )
    }
}

export default NavBar

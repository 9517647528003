import {Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper} from "@mui/material";
import {useState} from "react";
import MenuIcon from "@mui/icons-material/Menu";
import styles from './NavBar.module.css'
import {pages} from "./Pages";
import {Link as RouterLink} from "react-router-dom";
import logo from "../../assets/LOGO-NAV_BAR.png";

function DrawerComponent() {

    const [openDrawer, setOpenDrawer] = useState(false);

    return (
        <>
            <Drawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >

                <Paper className={styles.drawer}>
                    <div className={styles.imgDrawer}>
                        <img className={styles.img} src={logo} alt="cadastro Certo"/>
                    </div>

                    <List>
                        {pages.map((page, index) => (
                            <ListItemButton key={index} onClick={() => setOpenDrawer(false)} component={RouterLink}
                                            to={page.route}>
                                <ListItemIcon>
                                    <ListItemText>
                                        {page.page}
                                    </ListItemText>
                                </ListItemIcon>
                            </ListItemButton>

                        ))}
                    </List>
                </Paper>

            </Drawer>
            <IconButton sx={{color: 'white', marginLeft: 'auto'}} onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon/>

            </IconButton>
        </>
    )
}

export default DrawerComponent

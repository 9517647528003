import {useEffect, useMemo, useState} from "react";
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import styles from "./produto.module.css";
import load from "../../assets/load.gif";
import {AgGridReact} from "ag-grid-react";
import SendIcon from '@mui/icons-material/Send';
import {columnDefs, defaultColDef, localeText} from "./columnDefs";
import {Button} from "@mui/material";
import {ProdutosGrid, update} from './services/Produto.service'

function Produto() {

    const baseUrl = process.env.REACT_APP_API_CADASTRO_CERTO_URL
    console.log('baseUrl - ' + baseUrl)

    const token = localStorage.getItem("token");
    const [loading, setLoading] = useState(false);
    const [shoError, setShowError] = useState(false);
    const [loadingRows, setLoadingRows] = useState(false);
    const [produtoQty] = useState(10000);

    const [showGrid, setShowGrid] = useState(false)

    const [rowData, setRowData] = useState({
        pageNumber: 0,
        lastPage: false,
        data: []
    });

    const gridStyle = useMemo(
        () => ({
            height: '800px',
            width: '100%',
        }),
        []
    );

    const loadGrid = () => {
        setRowData((prevData) => ({
            pageNumber: 0,
            lastPage: false,
            data: [],
            load: true
        }));
    };

    const fetchData = async () => {
        setLoadingRows(true);
        setShowError(false);

        try {
            if (!rowData.lastPage) {
                const response = await getProdutos();

                if (response.status === 200) {

                    const data = await response.json();

                    const newData = {
                        pageNumber: data.size + data.startIndex,
                        data: [...rowData.data, ...data.content],
                        lastPage: data.lastPage
                    }

                    setRowData((prevData) => ({
                        ...prevData,
                        ...newData,
                    }));

                    setLoading(false);
                    setShowGrid(true);

                } else {
                    setLoading(false);
                    setShowError(true);
                }

            } else {
                setLoadingRows(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const getProdutos = async () => {

        let qnt = produtoQty;
        if (rowData.pageNumber === 0) {
            setLoading(true);
            qnt = 500;
        }
        return ProdutosGrid(qnt, rowData.pageNumber);
    }

    useEffect(() => {
        // if (rowData.load) { // para não carregar assim que abrir produtos
        //     fetchData().then();
        // }
        fetchData().then();
    }, [rowData]);

    const onCellEditingStopped = async (event) => {
        if (event.newValue !== event.oldValue) {
            const res = await update(event.data.id, event.newValue, event.column.getColDef().field).then()
            console.log(res);
        }
    }

    const onCellEditingStarted = async (event) => {
        console.log(event);
    }

    if (loading) {
        return (
            <div className={styles.loadingPage}><img className={styles.loadingImage} alt="loading" src={load}/></div>
        )
    } else {

        return (
            <>
                {shoError ? (<div><p>Erro ao Carregar Produtos</p></div>) : null}
                <div className={styles.options}>
                    <Button size="small" endIcon={<SendIcon/>} onClick={loadGrid} variant="contained">Carregar</Button>
                    <div className={styles.optionsInfos}>
                        <p> Total de Produtos : {rowData.data.length}</p>

                        {
                            loadingRows ? (
                                    <div className={styles.loadingSize}><img alt="loading" src={load}/></div>
                                ) :
                                null
                        }
                    </div>

                </div>
                {
                    showGrid ?
                        (
                            <div className="ag-theme-alpine" style={gridStyle}>
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowData={rowData.data}
                                    rowDragManaged={true}
                                    rowSelection="multiple"
                                    groupDisplayType={'groupRows'}
                                    animateRows={true}
                                    localeText={localeText}
                                    onCellEditingStarted={onCellEditingStarted}
                                    onCellEditingStopped={onCellEditingStopped}
                                />
                            </div>
                        ) :
                        (null)}
            </>
        )
    }
}

export default Produto

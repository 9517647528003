import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import NavBar from "../navBar/NavBar";
import Container from "../container/Container";
import {RequireAuth} from "react-auth-kit";
import LoginComponent from "../login/LoginComponent";
import LocalHome from "../home/Home";
import Produto from "../produto/Produto";


function RoutesComponent() {

    return (
        <div>
            <Router>
                <NavBar/>
                <Container customClass="full">
                    <Routes>
                        <Route path={'/login'} element={<LoginComponent/>}/>
                        <Route path={'/'} element={
                            <RequireAuth loginPath={'/login'}>
                                <LocalHome/>
                            </RequireAuth>
                        }/>

                        <Route path={'/produto'} element={
                            <RequireAuth loginPath={'/login'}>
                                <Produto/>
                            </RequireAuth>
                        }/>
                        <Route path="/login" element={<LoginComponent/>}></Route>
                    </Routes>
                </Container>
            </Router>
        </div>
    )
}

export default RoutesComponent

const baseUrl = process.env.REACT_APP_API_CADASTRO_CERTO_URL

export const ProdutosGrid = async (qnt, pageNumber) => {

    const token = localStorage.getItem("token");

    const url = `${baseUrl}/produto/findAllByParams`;
    const body = {
        status: ["MANUTENCAO",
            "CONFERIDO",
            "INATIVO",
            "PARCIAL"],
        embalagemCodigoBarras: [],
        enrich: false,
        isGridLoad: true,
        size: qnt,
        startIndex: pageNumber,
        sortBy: "descricao",
        direction: "ASC"
    };

    return fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body)
    });
}

export const update = async (id, newValue, field) => {

    const token = localStorage.getItem("token");

    const url = `${baseUrl}/produto/update`;
    const body = {
        ids: [id],
        [field]: newValue
    };

    return await fetch(url, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body)
    });
}

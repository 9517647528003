import {Home} from "@mui/icons-material";

function LocalHome() {


    return (<>
        <Home/>
    </>)
}

export default LocalHome
